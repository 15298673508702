/* Custom CSS for Ant Design Slider */
.ant-slider-track {
    background-color: rgb(0, 99, 191) !important; /* Blue for highlighted range */
  }
  
  .ant-slider-rail {
    background-color: grey !important; /* Grey for non-highlighted range */
  }
  .custom-input-number input {
    background-color: #000 !important;
    color: #fff !important;
  }
  
  /* Optionally style the up/down arrows */
  .custom-input-number .ant-input-number-handler-wrap {
    background-color: #000;
  }
  
  .custom-input-number .ant-input-number-handler-up-inner,
  .custom-input-number .ant-input-number-handler-down-inner {
    color: #fff;
  }
  