@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./tt-hoves/stylesheet.css");

@keyframes glow {
  0%, 100% {
      box-shadow: 0 0 2px rgba(255, 255, 255, 0.5), 0 0 3px rgba(255, 255, 255, 0.4), 0 0 6px rgba(255, 255, 255, 0.3);
  }
  50% {
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.75), 0 0 7.5px rgba(255, 255, 255, 0.6), 0 0 10px rgba(255, 255, 255, 0.5);
  }
}

.glow-on-hover:hover {
  animation: glow 3s infinite;
}

/* Style for the scrollbar track (part the handle slides within) */
::-webkit-scrollbar {
  width: 8px; /* for vertical scrollbars */
  height: 8px; /* for horizontal scrollbars */
}

/* Style for the handle (draggable part) of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: rgb(45, 45, 45);
  border-radius: 10px;
}

/* Style for the scrollbar track */
::-webkit-scrollbar-track {
  background: black;
  border-radius: 10px;
}



body {
  margin: 0;
  font-family: 'TT Hoves', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cardSpotlight::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(transparent, rgba(255,255,255,.5), transparent);
  animation: shootingStar 2s infinite;
}

.cardSpotlight::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, transparent, rgba(255,255,255,.5), transparent);
  animation: shootingStar 2s infinite;
}