.goldButton {
    background: linear-gradient(to right, #FFD700, #FFCC00);
    border: none;
    color: black;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 12px;
    box-shadow: 0 0 5px #B8860B, 0 0 10px #B8860B, 0 0 15px #B8860B, 0 0 20px #B8860B;
    font-weight: bold;
  }

  .goldButton2 {
    background: linear-gradient(to right, #FFD700, #FFCC00);
    border: none;
    color: black;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    margin-bottom: 30px;
    margin-top: 20px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 12px;
    box-shadow: 0 0 5px #B8860B, 0 0 10px #B8860B, 0 0 15px #B8860B, 0 0 20px #B8860B;
    font-weight: bold;
  }


  .goldButtonDashboard {
    background: linear-gradient(to right, #FFD700, #FFCC00);
    border: none;
    color: black;
    padding: 8px 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14.5px;
    /* margin: 4px 2px; */
    margin-bottom: 8px;
    margin-top: 0px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 0 1px #B8860B, 0 0 2.5px #B8860B, 0 0 4.5px #B8860B, 0 0 7.5px #B8860B;
    font-weight: bold;
  }