/* Custom CSS for Ant Design Slider */
.score-gradient-slider .ant-slider-track {
    background-color:rgb(0, 99, 191) !important; /* Blue for highlighted range */
  }
  
 .score-gradient-slider .ant-slider-rail {
    background-color: grey !important; /* Grey for non-highlighted range */
  }
  .score-gradient-slider .ant-slider-mark-text {
    /* Example styles */
    color: grey; /* Change the text color */
    font-weight: normal; /* Make the text bold */
    font-size: 12px; /* Adjust the font size */
    /* Add other styles as needed */
  }