.ag-theme-quartz,
.ag-theme-quartz-dark {
  --ag-foreground-color: rgb(255, 255, 255);
  --ag-background-color: rgb(0, 0, 0);
  --ag-header-foreground-color: #879097;
  --ag-header-background-color: rgb(0, 0, 0);
  --ag-header-column-resize-handle-color: rgb(0, 0, 0);
  --ag-card-radius: 10px;
  --ag-card-shadow: 0 10px 40px rgb(255, 255, 255);
  --ag-popup-shadow: var(--ag-card-shadow);
  --ag-tab-min-width: 150px;
  --ag-border-color: #000000; /* Example: setting the border color to red */
}
.ag-row {
  cursor: pointer;
}
.ag-cell-focus {
  outline: none !important;
  border: none !important;
}

.ag-row-focus {
  outline: none !important;
}

.ag-grid, .ag-theme-quartz, .ag-theme-quartz-dark {
  font-family: inherit; /* This makes the grid inherit the font family from its parent */
}
.ag-cell, .ag-header-cell {
  font-family: inherit;
}
.ag-header-cell-label {
  height: 20px !important; /* Adjust the height as needed */
}

.ag-header-cell {
  line-height: 20px !important; /* This helps vertically center the text, adjust accordingly */
}
/* Custom CSS to adjust header text size */
.ag-header-cell-label .ag-header-cell-text {
  font-size: 11px; /* Adjust the size as needed */
}

.ag-theme-quartz .ag-menu,
.ag-theme-quartz-dark .ag-menu {
  background-color: #55aa77;
}

.ag-theme-quartz .ag-tabs-header,
.ag-theme-quartz-dark .ag-tabs-header {
  background-color: #aa5577;
}

.ag-theme-quartz .ag-root-wrapper,
.ag-theme-quartz-dark .ag-root-wrapper {
  border-color: var(--ag-border-color);
}

.ag-theme-quartz .ag-cell,
.ag-theme-quartz-dark .ag-cell {
  border-color: var(--ag-border-color);
}

.ag-theme-quartz .ag-header-cell,
.ag-theme-quartz-dark .ag-header-cell {
  border-color: var(--ag-border-color);
}
