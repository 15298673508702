.customButton {
    background-color: transparent; /* Transparent background */
    color: #879097; /* Slightly off white text color */
    padding: 10px 16px; /* Padding inside the button */
    font-size: 16px; /* Text size */
    cursor: pointer; /* Change mouse cursor to pointer */
    transition: color 0.3s ease; /* Animate color change */
    outline: none; /* Remove outline */
    border: none; /* Remove border */
}

.customButtonWhite {
    background-color: transparent; /* Transparent background */
    color: #ffffff; /* Slightly off white text color */
    padding: 10px 20px; /* Padding inside the button */
    font-size: '10px'; /* Text size */
    font-weight: '600';
    cursor: pointer; /* Change mouse cursor to pointer */
    transition: color 0.3s ease; /* Animate color change */
    outline: none; /* Remove outline */
    border: none; /* Remove border */
}


/* Custom class for animating color and adding glow on hover */
.animate-color-and-glow {
    /* Smooth transition for color and text-shadow */
    transition: color 0.3s ease-in-out, text-shadow 0.3s ease-in-out;
}

.animate-color-and-glow:hover {
    /* Change to your desired hex color */
    color: #ffd900e4; 
    /* Add a glow effect */
    text-shadow: 0 0 1px #ffd900c2; /* Adjust the glow color and size as needed */
}

/* Add this to your navbarstyles.css */
@media (max-width: 768px) {
    .customButton {
        display: none; /* Hide buttons on small screens */
    }
    .navbar {
        padding: 20px 40px; /* Reduced padding on smaller screens */
    }
}


.customButton:hover {
    color: #fff; /* Change text color to white on hover */
}
