/* Style for the clear (x) button */
.ant-picker-clear {
  color: #ff4d4f !important; /* Red color for the x icon */
  background-color: #000000 !important; /* Maintain dark background */
}

/* Hover effect for the clear button */
.ant-picker-clear:hover {
  color: #ff7875 !important; /* Lighter red on hover */
}

/* Existing styles */
.ant-picker-input > input {
  background-color: #000000 !important;
  color: #fff !important;
}

.ant-picker-input > input::placeholder {
  color: #838891 !important;
}

.custom-dropdown-class .ant-picker-today-btn {
  color: #fff !important;
}

.ant-picker {
  border-color: #000 !important;
}

.ant-picker:hover, .ant-picker-focused {
  border-color: #5c6370 !important;
}