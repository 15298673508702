.video-style {
    width: 80%;
    height: auto;
    border-radius: 12px;
    /* box-shadow: 0 8px 16px rgba(50, 50, 50, 0.7); */
    object-fit: cover;
    margin: 0 auto;
    display: block;
    border: 2px solid #0000;
    margin-top: 20px;
    padding: 12px;
    background: linear-gradient(#131219, #131219) padding-box, linear-gradient(
        var(--angle),
        #070707,
        #687aff
      ) border-box;
    animation: 8s video-style-rotate linear infinite;
}


@keyframes video-style-rotate {
    to {
      --angle: 360deg;
    }
  }
  
  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }